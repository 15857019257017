import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'reactstrap';

import { renderField } from '../../../utils/form-utils';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const LoginForm = ({ handleSubmit, isSubmitting, isValid }) => {
  return (
    <Form
      onSubmit={handleSubmit}
      className="LoginForm"
    >
      <Field
        type="email"
        name="email"
        component={renderField}
        placeholder="Email address"
      />

      <Field
        type="password"
        name="password"
        component={renderField}
        placeholder="Password"
      />

      <Button
        type="submit"
        color="primary"
        disabled={!isValid || isSubmitting}
      >
        Login
      </Button>
    </Form>
  );
};

LoginForm.propTypes = propTypes;

export default withFormik({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  validationSchema: Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  }),
  handleSubmit: (values, formikBag) => {
    const { setSubmitting, props: { onSubmit } } = formikBag;
    onSubmit(values, setSubmitting);
  },
})(LoginForm);
