import React from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';
import PrivateRoute from './components/auth/PrivateRoute';
import Layout from './components/layout/Layout';

import DashboardPage from './pages/DashboardPage';
import Categories from './pages/categories';
import Deals from './pages/deals';
import Retailers from './pages/retailers';
import Rules from './pages/rules';

import {
  DASHBOARD_ROUTE,
  CATEGORIES_INDEX_ROUTE,
  DEALS_INDEX_ROUTE,
  RETAILERS_INDEX_ROUTE,
  RULES_INDEX_ROUTE,

} from './routes';

const App = () => (
  <ToastProvider>
    <Router>
      <Switch>
        <Route path="/login" exact component={LoginPage} />
        <Route path="/logout" exact component={LogoutPage} />

        <Layout>
          <PrivateRoute path={DASHBOARD_ROUTE} exact component={DashboardPage} />
          <PrivateRoute path={CATEGORIES_INDEX_ROUTE} component={Categories} />
          <PrivateRoute path={DEALS_INDEX_ROUTE} component={Deals} />
          <PrivateRoute path={RETAILERS_INDEX_ROUTE} component={Retailers} />
          <PrivateRoute path={RULES_INDEX_ROUTE} component={Rules} />
        </Layout>
      </Switch>
    </Router>
  </ToastProvider>
);

export default App;
