import React from 'react';

import Main from '../Main';
import Sidebar from '../Sidebar';
import Header from '../Header';

const Layout = ({ children }) => (
  <div className="Layout">
    <Sidebar />
    <Main>
      <Header />
      {children}
    </Main>
  </div>
);

export default Layout;
