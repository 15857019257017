import React, { Component } from 'react';

import UrlForm from './UrlForm';
import RetailerMapper from '../../../utils/mappers/retailer-mapper';
import { CategoryConsumer } from '../CategoriesEditPage/CategoryContext';
import BookFormatMapper from '../../../utils/mappers/book-format-mapper';

class UrlFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      retailers: [],
      bookFormats: [],
    };
  }

  async componentDidMount() {
    const res = await RetailerMapper.fetchAll();
    this.setState({ retailers: res.data.retailers });

    this.fetchBookFormats();
  }

  async fetchBookFormats() {
    const res = await BookFormatMapper.fetchAll();
    this.setState({ bookFormats: res.data.book_formats });
  }

  render() {
    const { retailers, bookFormats } = this.state;

    return (
      <CategoryConsumer>
        {({ onCreateUrl, categoryId }) => (
          <UrlForm
            onSubmit={onCreateUrl}
            retailers={retailers}
            bookFormats={bookFormats}
            categoryId={categoryId}
          />
        )}
      </CategoryConsumer>
    );
  }
}

export default UrlFormContainer;
