import api from '../api';

const validateOpts = ({ path }) => {
  if (!path) { new Error(`new Mapper: opts.path - expected: string, received: ${typeof path}`); }
};

class BaseMapper {
  constructor(opts = {}) {
    validateOpts(opts);
    Object.assign(this, opts);

    this.api = api;

    this.fetchAll = this.fetchAll.bind(this);
    this.fetch = this.fetch.bind(this);
    this.create = this.create.bind(this);
    this.patch = this.patch.bind(this);
    this.delete = this.delete.bind(this);
  }

  fetchAll(params) {
    return api.get(this.path, { params });
  }

  fetch(id, params = {}) {
    return api.get(`${this.path}/${id}`, { params });
  }

  create(payload) {
    return api.post(this.path, payload);
  }

  patch(id, payload) {
    return api.patch(`${this.path}/${id}`, payload);
  }

  delete(id) {
    return api.delete(`${this.path}/${id}`);
  }
}

export default BaseMapper;
