import React from 'react';
import { TabContent } from 'reactstrap';

import Widget from '../../../../components/layout/Widget';
import CategoryUrlsNav from './CategoryUrlsNav';
import UrlsList from '../../UrlsList';

const CategoryUrls = () => (
  <Widget
    label="URLs"
  >
    <CategoryUrlsNav />
    <TabContent>
      <UrlsList />
    </TabContent>
  </Widget>
);


export default CategoryUrls;
