import React from 'react';

import PrivateRoute from '../../components/auth/PrivateRoute';
import CategoriesListPage from './CategoriesListPage';
import CategoriesNewPage from './CategoriesNewPage';
import CategoriesEditPage from './CategoriesEditPage';

const Categories = ({ match: { url } }) => (
  <React.Fragment>
    <PrivateRoute path={`${url}`} exact component={CategoriesListPage} />
    <PrivateRoute path={`${url}/new`} exact component={CategoriesNewPage} />
    <PrivateRoute path={`${url}/:id/edit`} exact component={CategoriesEditPage} />
  </React.Fragment>
);

export default Categories;
