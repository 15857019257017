import React from 'react';
import PropTypes from 'prop-types';

import Widget from '../../../components/layout/Widget';

const propTypes = {
  retailers: PropTypes.array.isRequired,
};

const RetailersList = ({ retailers }) => (
  <div className="RetailersList">
    <Widget
      label="Retailers"
    >
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Key</th>
          </tr>
        </thead>

        <tbody>
          {retailers.map(retailer => (
            <tr key={retailer.id}>
              <td>{retailer.name}</td>
              <td><code>{retailer.key}</code></td>
            </tr>
          ))}
        </tbody>
      </table>
    </Widget>
  </div>
);

RetailersList.propTypes = propTypes;

export default RetailersList;
