import axios from 'axios';

import AuthService from './auth/auth-service';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const token = AuthService.getToken();
  if (token) { config.headers.Authorization = `Bearer ${token}`; }
  return config;
});

api.interceptors.response.use((res) => {
  return res;
}, (err) => {
  if (err.response.status === 401) {
    AuthService.logout();
  }
  return Promise.reject(err);
});

export default api;
