import React, { Component } from 'react';

import CategoriesList from '../CategoriesList';
import CategoryMapper from '../../../utils/mappers/category-mapper';
import { confirmDelete } from '../../../utils/alert-utils';

class CategoriesListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
    };

    this.fetchCategories = this.fetchCategories.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleActivateAll = this.handleActivateAll.bind(this);
    this.handleDeactivateAll = this.handleDeactivateAll.bind(this);
  }

  async componentDidMount() {
    this.fetchCategories();
  }

  async fetchCategories() {
    const res = await CategoryMapper.fetchAll();
    this.setState({ categories: res.data.categories });
  }

  handleDelete(id) {
    confirmDelete(async () => {
      await CategoryMapper.delete(id);
      this.fetchCategories();
    });
  }

  async handleActivateAll() {
    await CategoryMapper.activateAll();
    this.fetchCategories();
  }

  async handleDeactivateAll() {
    await CategoryMapper.deactivateAll();
    this.fetchCategories();
  }

  async handleToggle(category, active) {
    await CategoryMapper.patch(category.id, { category: Object.assign(category, { active }) });
    this.fetchCategories();
  }

  render() {
    const { categories } = this.state;
    const { match: { url } } = this.props;

    return (
      <div>
        <CategoriesList
          categories={categories}
          newUrl={`${url}/new`}
          onDelete={this.handleDelete}
          onToggle={this.handleToggle}
          onActivateAll={this.handleActivateAll}
          onDeactivateAll={this.handleDeactivateAll}
        />
      </div>
    );
  }
}

export default CategoriesListPage;
