import React from 'react';

import UrlsList from './UrlsList';
import { CategoryConsumer } from '../CategoriesEditPage/CategoryContext';

const UrlsListContainer = () => (
  <CategoryConsumer>
    {({ urls, onDeleteUrl }) => (
      <UrlsList
        urls={urls}
        onDelete={onDeleteUrl}
      />
    )}
  </CategoryConsumer>
);

export default UrlsListContainer;
