import { isTokenExpired } from './jwt-helper';

import api from '../api';

const tokenKey = 'savvy_token';
const profileKey = 'savvy_profile';

class AuthService {
  constructor() {
    this.login = this.login.bind(this);
  }

  login(params) {
    const { email, password } = params

    return new Promise((resolve, reject) => {
      api.post(`${process.env.REACT_APP_BASE_API_URL}/auth/token`, {
        auth: { email, password },
      }
      )
        .then(res => {
          const { jwt, user } = res.data;
          this.setAuthTokens(jwt, user);
          resolve(jwt);
        })
        .catch(() => {
          reject('Wrong email or password.');
        })
    })
  }

  getToken() {
    // Retrieves the user token from localStorage
    return localStorage.getItem(tokenKey);
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken();
    return !!token && !isTokenExpired(token);
  }

  setToken(jwt) {
    // Saves jwt into local storage
    localStorage.setItem(tokenKey, jwt);
  }

  setProfile(profile) {
    // Saves profile data to localStorage
    localStorage.setItem(profileKey, JSON.stringify(profile));
  }

  setAuthTokens(jwt, profile) {
    this.setToken(jwt);
    this.setProfile(profile);
  }

  getProfile() {
    // Retrieves the profile data from localStorage
    const profile = localStorage.getItem(profileKey);
    return profile ? JSON.parse(profile) : {};
  }

  logout() {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(profileKey);
    window.location.href = '/login';
  }
}

export default new AuthService();
