import React from 'react';

import PrivateRoute from '../../components/auth/PrivateRoute';
import DealsListPage from './DealsListPage';

const Deals = ({ match: { url } }) => (
  <React.Fragment>
    <PrivateRoute path={`${url}`} exact component={DealsListPage} />
  </React.Fragment>
);

export default Deals;
