import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';

import { renderField, REQUIRED_MSG } from '../../../utils/form-utils';

const EditModal = ({ onToggle: toggle, isOpen, uidConvert, handleSubmit, isValid, isSubmitting }) => {
  const { ean, asin } = uidConvert;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Update Uid Convert</ModalHeader>

        <Form onSubmit={handleSubmit}>
          <ModalBody>
            {!asin && (
              <Field
                name="asin"
                label="ASIN"
                component={renderField}
                required
              />
            )}

            {!ean && (
              <Field
                name="ean"
                label="EAN"
                component={renderField}
                required
              />
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              type="submit"
              color="primary"
              disabled={!isValid || isSubmitting}
            >
              Update
            </Button>
            {' '}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: ({ uidConvert }) => {
    const { ean, asin, id } = uidConvert;

    return {
      id,
      ean: (ean || ''),
      asin: (asin || ''),
    };
  },
  validationSchema: Yup.object().shape({
    asin: Yup.string().required(REQUIRED_MSG),
    ean: Yup.string().required(REQUIRED_MSG),
  }),
  handleSubmit: (values, formikBag) => {
    const { props: { onSubmit } } = formikBag;

    return onSubmit(values, formikBag);
  },
})(EditModal);
