import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import { find } from 'lodash';

import { renderField, REQUIRED_MSG } from '../../../utils/form-utils';
import Widget from '../../../components/layout/Widget';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  rootCategories: PropTypes.array,
  bookFormats: PropTypes.array,
};

const defaultProps = {
  initialValues: {},
  rootCategories: [],
  bookFormats: [],
};

const renderRootSelect = (initialValues, rootCategories) => {
  const { id, parent_id } = initialValues;

  if (!id) {
    return (
      <Field
        type="select"
        name="parent_id"
        label="Parent"
        component={renderField}
      >
        <option />
        {rootCategories.map(({ id, title }) => (
          <option
            value={id}
            key={id}
          >
            {title}
          </option>
        ))}
      </Field>
    );
  }

  const root = find(rootCategories, { id: parseInt(parent_id, 10) });
  if (!root) { return null; }

  if (id) {
    return (
      <Field
        name="parent_id"
        label="Parent"
        component={renderField}
        value={`${root.title} (id: ${root.id})`}
        readOnly
      />
    );
  }
}

const CategoryForm = ({ handleSubmit, isSubmitting, isValid, initialValues, rootCategories }) => {
  const { id, title } = initialValues;

  return (
    <Form onSubmit={handleSubmit}>
      <Widget
        label={id ? `Edit ${title} Category` : "New Category"}
        footer={
          <React.Fragment>
            <Button
              type="submit"
              color="primary"
              disabled={!isValid || isSubmitting}
            >
              Save
            </Button>

            <Link to="/categories" className="btn btn-outline-secondary cancel">
              Cancel
            </Link>
          </React.Fragment>
        }
      >
        <React.Fragment>
          <Field
            name="title"
            label="Title"
            component={renderField}
            warningText="This field is used by SalesForce. Update at your own risk."
            required
          />

          <Field
            name="public_title"
            label="Public Title"
            component={renderField}
            helpText="Used on website to display category. If not present, title above will be used."
          />

          {renderRootSelect(initialValues, rootCategories)}

          <Field
            type="switch"
            name="active"
            label="Active"
            component={renderField}
          />
        </React.Fragment>
      </Widget>
    </Form>
  );
};

CategoryForm.propTypes = propTypes;
CategoryForm.defaultProps = defaultProps;

const setValidationSchema = () => Yup.object().shape({
  title: Yup.string().required(REQUIRED_MSG),
});

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => {
    const { id, parent_id, public_title } = initialValues;

    const defaults = {
      title: '',
      public_title: '',
      parent_id: '',
      active: false,
    };

    if (id) {
      Object.assign(defaults, initialValues, {
        parent_id: (parent_id || ''),
        public_title: (public_title || ''),
      });
    }

    return defaults;
  },
  validationSchema: () => setValidationSchema(),
  handleSubmit: (values, formikBag) => {
    const { props: { onSubmit } } = formikBag;

    if (!values.parent_id) {
      const { parent_id, ...rest } = values;
      return onSubmit(rest, formikBag);
    }

    onSubmit(values, formikBag);
  },
  isInitialValid: ({ initialValues }) => {
    const schema = setValidationSchema();
    const result = schema.isValidSync(initialValues);
    return result;
  }
})(CategoryForm);
