import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import AuthService from '../../utils/auth/auth-service';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      AuthService.loggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{
          pathname: '/login',
          search: props.location.search,
          state: { from: props.location },
        }}
        />
      ))}
  />
);

export default PrivateRoute;
