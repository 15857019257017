import React from 'react';
import { Table, Badge, Button } from 'reactstrap';

import { makeRetailerUrl } from '../../../utils/url-utils';

const UidConvertList = ({ uidConverts, handleOnEdit, onDelete }) => {
  if (!uidConverts.length) {
    return "You're awesome. No failed converts at this time.";
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Status</th>
          <th>From</th>
          <th>To</th>
          <th>ASIN</th>
          <th>EAN</th>
          <th>Edit</th>
        </tr>
      </thead>

      <tbody>
        {uidConverts.map(convert => (
          <tr key={convert.id}>
            <td>{convert.id}</td>
            <td>{convert.status}</td>
            <td>{convert.from_retailer.name}</td>
            <td>{convert.to_retailer.name}</td>
            <td>
              {convert.asin ? (
                <Badge color="success">{convert.asin}</Badge>
              ) : (
                <Badge color="danger">needed</Badge>
              )}
            </td>
            <td>
              {convert.ean ? (
                <Badge color="success">{convert.ean}</Badge>
              ) : (
                <Badge color="danger">needed</Badge>
              )}
            </td>
            <td>
              <a
                className="btn btn-link btn-sm"
                href={makeRetailerUrl(convert)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-link" />
              </a>

              <Button
                size="sm"
                color="secondary"
                onClick={() => handleOnEdit(convert)}
              >
                Edit
              </Button>

              <Button
                size="sm"
                color="danger"
                onClick={() => onDelete(convert)}
              >
                Delete
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default UidConvertList;
