import React from 'react';
import { FormFeedback, FormGroup, Label, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import classnames from 'classnames';
import { get } from 'lodash';
import Switch from 'react-switch';

import { getBrandColor } from './colors';

export const REQUIRED_MSG = 'required';

const renderText = (text, textStyle) => {
  if (!text) { return null; }
  return <small className={`form-text ${textStyle}`}>{text}</small>;
};

const renderHelpText = text => renderText(text, 'text-muted');
const renderWarningText = text => renderText(text, 'text-danger');

export const renderField = (props) => {
  const { type, form, field, label, required, prepend, append } = props;
  const { helpText, warningText, ...fieldProps } = props;
  const { name, value } = field;
  const { errors, touched } = form;
  const isInvalid = !!get(errors, name) && get(touched, name);

  let input;

  switch (type) {
    case 'checkbox':
      return (
        <FormGroup check>
          <Input
            type="checkbox"
            name="check"
            {...field}
            {...fieldProps}
          />
          <Label check>{label}</Label>
        </FormGroup>
      );

    case 'switch':
      input = (
        <FormGroup>
          <Switch
            onChange={() => {
              form.setFieldValue(name, !value);
              form.setFieldTouched(name, true);
            }}
            checked={value}
            checkedIcon={false}
            onColor={getBrandColor('success')}
          />
        </FormGroup>
      );
      break;

    default: {
      input = (
        <Input
          type={type}
          {...field}
          {...fieldProps}
          invalid={isInvalid}
        />
      );
    }
  }

  if (prepend) {
    input = (
      <InputGroup className={get(touched, name) && get(errors, name) && 'is-invalid'}>
        <InputGroupAddon addonType="prepend">{prepend}</InputGroupAddon>
        {input}
      </InputGroup>
    );
  }

  if (append) {
    input = (
      <InputGroup className={get(touched, name) && get(errors, name) && 'is-invalid'}>
        {input}
        <InputGroupAddon addonType="append">{append}</InputGroupAddon>
      </InputGroup>
    );
  }

  return (
    <FormGroup>
      {label && (
        <Label for={label} className={classnames({ required })}>
          {label}
        </Label>
      )}
      {input}
      {renderHelpText(helpText)}
      {renderWarningText(warningText)}
      {get(touched, name) && get(errors, name) && (
        <FormFeedback>{get(errors, name)}</FormFeedback>
      )}
    </FormGroup>
  );
};
