import React, { Component } from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';

import DealMapper from '../../utils/mappers/deal-mapper';
import UidConvertMapper from '../../utils/mappers/uid-convert-mapper';
import UidConvertList from './UidConvertList';
import EditModal from './EditModal';
import { confirmDelete } from '../../utils/alert-utils';

class DashboardPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: {},
      uidConverts: [],
      isModalOpen: false,
      editingUIDConvert: null,
    };

    this.fetchDealStats = this.fetchDealStats.bind(this);
    this.fetchUidConverts = this.fetchUidConverts.bind(this);
    this.handleOnEdit = this.handleOnEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    this.fetchDealStats();
    this.fetchUidConverts();
  }

  async fetchDealStats() {
    const res = await DealMapper.getStats();
    this.setState({ stats: res.data.stats });
  }

  async fetchUidConverts() {
    const res = await UidConvertMapper.fetchAll();
    this.setState({ uidConverts: res.data.uid_converts });
  }

  handleOnEdit(convert) {
    this.setState(prevState => ({
      isModalOpen: !prevState.isModalOpen,
      editingUIDConvert: Object.assign({}, convert),
    }));
  }

  handleDelete(convert) {
    const { toastManager } = this.props;

    confirmDelete(async () => {
      await UidConvertMapper.delete(convert.id);
      this.fetchUidConverts();

      toastManager.add('Successfully removed uid convert.', {
        appearance: 'success',
        autoDismiss: true,
      });
    });
  }

  handleToggle() {
    this.setState(({ isModalOpen }) => ({
      isModalOpen: !isModalOpen,
      editingUIDConvert: null,
    }));
  }

  async handleSubmit(uid_convert, formikBag) {
    const { toastManager } = this.props;

    try {
      await UidConvertMapper.patch(uid_convert.id, { uid_convert });

      formikBag.setSubmitting(false);
      toastManager.add('Successfully updated uid convert.', {
        appearance: 'success',
        autoDismiss: true,
      });
      this.handleToggle();
      this.fetchUidConverts();
    } catch (e) {
      formikBag.setErrors(e.response.data.error.message);
      formikBag.setSubmitting(false);
    }
  }

  render() {
    const { stats, uidConverts, editingUIDConvert, isModalOpen } = this.state;

    return (
      <div className="DashboardPage">
        <Card className="mb-3">
          <CardBody>
            <Row>
              <Col md="4">
                <div>Active Deals</div>
                <h3>{stats.active}</h3>
              </Col>

              <Col md="4">
                <div>Pending Deals</div>
                <h3>{stats.pending}</h3>
              </Col>

              <Col md="4">
                <div>Inactive Deals</div>
                <h3>{stats.inactive}</h3>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h5>Failed Converts</h5>

            <UidConvertList
              uidConverts={uidConverts}
              handleOnEdit={this.handleOnEdit}
              onDelete={this.handleDelete}
            />

            { editingUIDConvert && (
              <EditModal
                isOpen={isModalOpen}
                callback={this.handleOnEdit}
                onToggle={this.handleToggle}
                uidConvert={editingUIDConvert}
                onSubmit={this.handleSubmit}
              />
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withToastManager(DashboardPage);
