import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';

import UrlMapper from '../../../utils/mappers/url-mapper';
import { confirmDelete } from '../../../utils/alert-utils';

const { Provider, Consumer } = React.createContext();

const propTypes = {
  categoryId: PropTypes.string.isRequired,
  children: PropTypes.element,
};

const defaultProps = {
  children: null,
};

class CategoryProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urls: [],
    };

    this.fetchUrls = this.fetchUrls.bind(this);
    this.handleCreateUrl = this.handleCreateUrl.bind(this);
    this.handleDeleteUrl = this.handleDeleteUrl.bind(this);
  }

  componentDidMount() {
    this.fetchUrls();
  }

  async fetchUrls() {
    const { categoryId } = this.props;
    const res = await UrlMapper.fetchAll({ category_id: categoryId, per_page: 100 });
    this.setState({ urls: res.data.urls });
  }

  async handleCreateUrl(values, formikBag) {
    const { toastManager } = this.props;
    try {
      await UrlMapper.create(values);
      toastManager.add('Successfully created URL.', {
        appearance: 'success',
        autoDismiss: true,
      });
      formikBag.resetForm();
      this.fetchUrls();
    } catch (e) {
      formikBag.setErrors(e.response.data.error.message);
    }
    formikBag.setSubmitting(false);
  }

  async handleDeleteUrl(id) {
    confirmDelete(async () => {
      const { toastManager } = this.props;
      await UrlMapper.delete(id);
      toastManager.add('Successfully removed URL.', {
        appearance: 'success',
        autoDismiss: true,
      });
      this.fetchUrls();
    });
  }

  render() {
    const { categoryId, children } = this.props;
    const { urls } = this.state;

    return (
      <Provider
        value={{
          categoryId,
          urls,
          onCreateUrl: this.handleCreateUrl,
          onDeleteUrl: this.handleDeleteUrl,
        }}
      >
        {children}
      </Provider>
    );
  }
}

CategoryProvider.propTypes = propTypes;
CategoryProvider.defaultProps = defaultProps;
const wrappedCategoryProvder = withToastManager(CategoryProvider);
export { wrappedCategoryProvder as CategoryProvider, Consumer as CategoryConsumer };
