import React, { Component } from 'react';
import { Alert } from 'reactstrap';

import LoginForm from './LoginForm';
import AuthService from '../../utils/auth/auth-service';

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      err: null,
    };

    this.login = this.login.bind(this);
  }

  login(params, setSubmitting) {
    AuthService.login(params)
      .then(() => {
        setSubmitting(false);
        this.props.history.push('/');
      })
      .catch((err) => {
        setSubmitting(false);
        this.setState({ err });
      })
  }

  render() {
    const { err } = this.state;

    return (
      <div className="LoginPage">
        <div className="LoginPage__wrap">
          <div className="LoginPage__logo">Shelf Savvy <span>HQ</span></div>
          <div className="LoginPage__modal">
            {err &&
              <Alert color="danger">{err}</Alert>
            }

            <LoginForm onSubmit={(params, setSubmitting) => this.login(params, setSubmitting)} />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
