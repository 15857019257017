export const DASHBOARD_ROUTE = '/';

export const CATEGORIES_INDEX_ROUTE = '/categories';
export const CATEGORIES_EDIT_ROUTE = '/categories/:id/edit';

export const DEALS_INDEX_ROUTE = '/deals';

export const RETAILERS_INDEX_ROUTE = '/retailers';

export const RULES_INDEX_ROUTE = '/rules';

const routes = {
  'categories.index': CATEGORIES_INDEX_ROUTE,
  'categories.edit': CATEGORIES_EDIT_ROUTE,
  'deals.index': DEALS_INDEX_ROUTE,
  dashboard: DASHBOARD_ROUTE,
  'retailers.index': RETAILERS_INDEX_ROUTE,
  'rules.index': RULES_INDEX_ROUTE,
};

export default routes;
