import BaseMapper from './base-mapper';

const PATH = '/categories';

class CategoryMapper extends BaseMapper {
  activateAll() {
    return this.api.patch(`${PATH}/activate`);
  }

  deactivateAll() {
    return this.api.patch(`${PATH}/deactivate`);
  }
}

export default new CategoryMapper({
  path: PATH,
});
