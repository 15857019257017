import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

const CategoryUrlsNav = () => {
  return (
    <Nav tabs>
      <NavItem>
        <NavLink className="active">
          Amazon
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default CategoryUrlsNav;
