import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Input } from 'reactstrap';

import Widget from '../../../components/layout/Widget';

const propTypes = {
  deals: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

const renderStatusBadge = (status) => {
  const colorMap = {
    pending: 'warning',
    active: 'success',
  };

  return (
    <Badge color={(colorMap[status] || 'secondary')}>{status}</Badge>
  );
};

const DealsList = ({ deals, onDelete, onSearch }) => (
  <div className="DealsList">
    <Widget
      label="Deals"
      actions={(
        <Input
          onChange={e => onSearch(e.target.value)}
          placeholder="search by title..."
          style={{ width: '400px' }}
        />
      )}
    >
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th className="text-center">Status</th>
            <th className="text-center">Retailer</th>
            <th className="text-center">Format</th>
            <th className="text-right">Sale Price</th>
            <th />
          </tr>
        </thead>

        <tbody>
          {deals.map(deal => (
            <tr key={deal.id}>
              <td>{deal.id}</td>
              <td>{deal.book_variant.title}</td>
              <td className="text-center">{renderStatusBadge(deal.status)}</td>
              <td className="text-center">{deal.retailer.name}</td>
              <td className="text-center">{deal.book_variant.book_format.name}</td>
              <td className="text-right">{deal.sale_price}</td>
              <td>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => onDelete(deal.id)}
                  title="Delete Deal"
                >
                  <i className="far fa-trash-alt" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Widget>
  </div>
);

DealsList.propTypes = propTypes;

export default DealsList;
