import React, { Component } from 'react';

import RetailerMapper from '../duck/retailer-mapper';
import RetailersList from '../RetailersList';

class RetailersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      retailers: [],
    };
  }

  async componentDidMount() {
    const res = await RetailerMapper.fetchAll();
    this.setState({ retailers: res.data.retailers });
  }

  render() {
    const { retailers } = this.state;

    return (
      <RetailersList
        retailers={retailers}
      />
    );
  }
}

export default RetailersPage;
