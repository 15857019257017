import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
  <header className="Header">
    <div className="Header__actions">
      <div className="Header__actionItem logout">
        <Link to="/logout">
          <i className="fas fa-sign-out-alt" />
        </Link>
      </div>
    </div>
  </header>
);

export default Header;
