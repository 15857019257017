import React, { Component } from 'react';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';
import { reject } from 'lodash';

import CategoryForm from '../CategoryForm';
import CategoryMapper from '../../../utils/mappers/category-mapper';
import { CategoryProvider } from './CategoryContext';
import CategoryUrls from './CategoryUrls';

class CategoriesEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: null,
      rootCategories: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const { match } = this.props;
    const res = await CategoryMapper.fetch(match.params.id);
    this.setState({ category: res.data.category });
    this.fetchRootCategories();
  }

  async fetchRootCategories() {
    const res = await CategoryMapper.fetchAll({ is_root: true });
    this.setState({ rootCategories: res.data.categories });
  }

  async handleSubmit(category, formikBag) {
    const { toastManager } = this.props;
    try {
      await CategoryMapper.patch(category.id, { category });
      toastManager.add('Successfully updated category.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e) {
      formikBag.setErrors(e.response.data.error.message);
    }
    formikBag.setSubmitting(false);
  }

  render() {
    const { category, rootCategories } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    if (!category || !rootCategories) {
      return null;
    }

    return (
      <CategoryProvider categoryId={id}>
        <React.Fragment>
          <CategoryForm
            rootCategories={reject(rootCategories, { id: parseInt(id, 10) })}
            onSubmit={this.handleSubmit}
            initialValues={category}
          />

          { category.parent_id && <CategoryUrls /> }
        </React.Fragment>
      </CategoryProvider>
    );
  }
}

export default withToastManager(CategoriesEditPage);
