import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { Button } from 'reactstrap';

import { renderField, REQUIRED_MSG } from '../../../utils/form-utils';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  retailers: PropTypes.array.isRequired,
  bookFormats: PropTypes.array.isRequired,
  categoryId: PropTypes.string.isRequired,
};

const UrlForm = ({ handleSubmit, isSubmitting, isValid, retailers, bookFormats }) => {
  return (
    <tr>
      <td>
        <Field
          name="value"
          component={renderField}
          placeholder="url"
        />
      </td>

      <td>
        <Field
          type="select"
          name="retailer_id"
          component={renderField}
        >
          <option />
          {retailers.map(retailer => (
            <option
              key={retailer.id}
              value={retailer.id}
            >
              {retailer.name}
            </option>
          ))}
        </Field>
      </td>

      <td>
        <Field
          type="select"
          name="book_format_id"
          component={renderField}
        >
          <option />
          {bookFormats.map(bookFormat => (
            <option
              key={bookFormat.id}
              value={bookFormat.id}
            >
              {bookFormat.name}
            </option>
          ))}
        </Field>
      </td>

      <td>
        <Button
          type="submit"
          color="primary"
          size="sm"
          disabled={!isValid || isSubmitting}
          onClick={handleSubmit}
        >
          add
        </Button>
      </td>
    </tr>
  );
};

UrlForm.propTypes = propTypes;

export default withFormik({
  mapPropsToValues: ({ categoryId }) => ({
    value: '',
    retailer_id: '',
    book_format_id: '',
    category_id: categoryId,
  }),
  validationSchema: Yup.object().shape({
    value: Yup.string().required(REQUIRED_MSG),
    retailer_id: Yup.string().required(REQUIRED_MSG),
    book_format_id: Yup.number().required(REQUIRED_MSG),
  }),
  handleSubmit: (values, formikBag) => {
    const { props: { onSubmit } } = formikBag;
    onSubmit(values, formikBag);
  },
})(UrlForm);
