import React from 'react';
import { NavLink } from 'react-router-dom';

import getRoute from '../../../utils/get-route';

const Sidebar = () => (
  <div className="Sidebar">
    <div className="Sidebar__header">
      <div className="Sidebar__logo">
        Shelf Savvy
        {' '}
        <span>HQ</span>
      </div>
    </div>

    <div className="Sidebar__body">
      <ul className="Sidebar__links">
        <li>
          <NavLink
            to={getRoute('dashboard')}
            className="Sidebar__link"
            isActive={match => (match.url === '/')}
          >
            <i className="fas fa-home" />
            <span>Dashboard</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={getRoute('deals.index')}
            className="Sidebar__link"
            activeClassName="active"
          >
            <i className="fas fa-dollar-sign" />
            <span>Deals</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={getRoute('categories.index')}
            className="Sidebar__link"
            activeClassName="active"
          >
            <i className="fas fa-tags" />
            <span>Categories</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={getRoute('retailers.index')}
            className="Sidebar__link"
            activeClassName="active"
          >
            <i className="fas fa-shopping-basket" />
            <span>Retailers</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={getRoute('rules.index')}
            className="Sidebar__link"
            activeClassName="active"
          >
            <i className="fa fa-book" />
            <span>Rules</span>
          </NavLink>
        </li>
      </ul>
    </div>
  </div>
);

export default Sidebar;
