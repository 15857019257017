import React from 'react';

const Widget = ({ label, actions, children, footer }) => (
  <div className="Widget">
    <div className="Widget__head">
      <div className="Widget__label">
        <h3 className="Widget__label-title">{label}</h3>
      </div>

      <div className="Widget__actions">
        {actions}
      </div>
    </div>

    <div className="Widget__body">
      {children}
    </div>

    {footer && (
      <div className="Widget__footer">
        {footer}
      </div>
    )}
  </div>
);

export default Widget;
