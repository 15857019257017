import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Badge, UncontrolledTooltip } from 'reactstrap';
import Switch from 'react-switch';

import Widget from '../../../components/layout/Widget';
import getRoute from '../../../utils/get-route';
import { getBrandColor } from '../../../utils/colors';

const propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  })).isRequired,
  onToggle: PropTypes.func.isRequired,
  newUrl: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onActivateAll: PropTypes.func.isRequired,
  onDeactivateAll: PropTypes.func.isRequired,
};

const CategoriesList = ({
  categories,
  newUrl,
  onDelete,
  onToggle,
  onActivateAll,
  onDeactivateAll,
}) => (
  <div className="CategoriesList">
    <Widget
      label="Categories"
      actions={(
        <React.Fragment>
          <Link
            to={newUrl}
            className="btn btn-primary"
          >
            New Category
          </Link>

          <Button
            color="success"
            onClick={onActivateAll}
          >
            Activate All
          </Button>

          <Button
            color="warning"
            onClick={onDeactivateAll}
          >
            Deactivate All
          </Button>
        </React.Fragment>
      )}
    >
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th className="text-center">
              Is Root
              <span href="#" id="UncontrolledTooltipFilter">
                <i className="fa fa-info-circle pl-1" />
              </span>

              <UncontrolledTooltip placement="top" target="UncontrolledTooltipFilter">
                Will Not Appear in Filter
              </UncontrolledTooltip>
            </th>
            <th className="text-center">Active</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>

        <tbody>
          {categories.map(category => (
            <tr key={category.id}>
              <td>{category.id}</td>
              <td>{category.title}</td>
              <td className="text-center">
                <Badge color={(category.parent_id ? 'secondary' : 'primary')}>
                  {(category.parent_id ? 'false' : 'true')}
                </Badge>
              </td>
              <td className="text-center">
                <Switch
                  onChange={() => onToggle(category, !category.active)}
                  checked={category.active}
                  checkedIcon={false}
                  onColor={getBrandColor('success')}
                />
              </td>
              <td className="text-center">
                <React.Fragment>
                  <Link
                    to={getRoute('categories.edit', category.id)}
                    className="btn btn-sm btn-link"
                  >
                    <i className="fas fa-cog" />
                  </Link>

                  <Button
                    onClick={() => onDelete(category.id)}
                    color="link"
                    className="danger"
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>
                </React.Fragment>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Widget>
  </div>
  );

CategoriesList.propTypes = propTypes;

export default CategoriesList;
