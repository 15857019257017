import BaseMapper from './base-mapper';

const PATH = '/deals';

class DealMapper extends BaseMapper {
  getStats() {
    return this.api.get(`${PATH}/stats`);
  }
}

export default new DealMapper({
  path: PATH,
});
