export const makeRetailerUrl = (uidConvert) => {
  const { asin, ean } = uidConvert;

  const amazonUrl = 'https://www.amazon.com';
  const bnUrl = 'https://www.barnesandnoble.com';

  if (asin) {
    return `${amazonUrl}/dp/${asin}`;
  }

  if (ean) {
    return `${bnUrl}/s/${ean}`;
  }

  return null;
};

export default {
  makeRetailerUrl,
};
