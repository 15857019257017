import React, { Component } from 'react';
import { withToastManager } from 'react-toast-notifications';

import CategoryForm from '../CategoryForm';
import CategoryMapper from '../../../utils/mappers/category-mapper';
import getRoute from '../../../utils/get-route';

class CategoriesNewPage extends Component {
  state = {
    rootCategories: null,
  };

  componentDidMount = () => this.fetchRootCategories();

  fetchRootCategories = async () => {
    const res = await CategoryMapper.fetchAll({ is_root: true });
    this.setState({ rootCategories: res.data.categories });
  }

  handleSubmit = async (values, formikBag) => {
    const { toastManager, history } = this.props;

    try {
      const res = await CategoryMapper.create({ category: values });
      const { id } = res.data.category;

      toastManager.add('Successfully added category.', {
        appearance: 'success',
        autoDismiss: true,
      });
      history.push(getRoute('categories.edit', id));
    } catch (e) {
      formikBag.setErrors(e.response.data.error.message);
    }
    formikBag.setSubmitting(false);
  }

  render() {
    const { rootCategories } = this.state;

    if (!rootCategories) { return null; }

    return (
      <CategoryForm
        onSubmit={this.handleSubmit}
        rootCategories={rootCategories}
      />
    );
  }
}

export default withToastManager(CategoriesNewPage);
