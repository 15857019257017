import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Form, InputGroupText } from 'reactstrap';
import { Link } from 'react-router-dom';

import { renderField, REQUIRED_MSG } from '../../../utils/form-utils';
import Widget from '../../../components/layout/Widget';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  initialValues: {},
};

const RuleForm = ({ handleSubmit, isSubmitting, isValid }) => {
  return (
    <Form onSubmit={handleSubmit}>
      <Widget
        label="Edit Rules"
        footer={(
          <React.Fragment>
            <Button
              type="submit"
              color="primary"
              disabled={!isValid || isSubmitting}
            >
              Save
            </Button>

            <Link
              to="/"
              className="btn btn-outline-secondary cancel"
            >
              Cancel
            </Link>
          </React.Fragment>
        )}
      >
        <React.Fragment>
          <Field
            type="number"
            name="ebook_deal_price"
            label="Ebook Deal Price"
            component={renderField}
            required
            prepend={(
              <InputGroupText>$</InputGroupText>
            )}
          />

          <Field
            type="number"
            name="print_deal_discount"
            label="Print Deal Discount"
            component={renderField}
            required
            append={(
              <InputGroupText>%</InputGroupText>
            )}
          />

          <Field
            type="number"
            name="audiobook_deal_discount"
            label="Audiobook Deal Discount"
            component={renderField}
            required
            append={(
              <InputGroupText>%</InputGroupText>
            )}
          />
        </React.Fragment>
      </Widget>
    </Form>
  );
};

RuleForm.propTypes = propTypes;
RuleForm.defaultProps = defaultProps;

const setValidationSchema = () => Yup.object().shape({
  ebook_deal_price: Yup.number().required(REQUIRED_MSG).min(0),
  print_deal_discount: Yup.number().required(REQUIRED_MSG).min(0).max(100),
});

export default withFormik({
  mapPropsToValues: ({ initialValues = {} }) => {
    const { id } = initialValues;

    const defaults = {
      ebook_deal_price: 0,
      print_deal_discount: 0
    };

    if (id) {
      Object.assign(defaults, initialValues);
    }

    return defaults;
  },
  validationSchema: () => setValidationSchema(),
  handleSubmit: (values, formikBag) => {
    console.log(values);
    const { props: { onSubmit } } = formikBag;
    onSubmit(values, formikBag);
  },
  isInitialValid: () => true,
})(RuleForm);
