import React from 'react';

import PrivateRoute from '../../components/auth/PrivateRoute';
import RuleEditPage from './RuleEditPage';

const Rules = ({ match: { url } }) => (
  <React.Fragment>
    <PrivateRoute path={`${url}`} exact component={RuleEditPage} />
  </React.Fragment>
);

export default Rules;
