import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';

import DealsList from '../DealsList';
import DealMapper from '../../../utils/mappers/deal-mapper';
import { confirmDelete } from '../../../utils/alert-utils';

const MIN_CHAR_COUNT = 3;

class DealsListPage extends Component {
  fetchDeals = debounce(async (currentPage, title = '') => {
    let extras = {};
    const { perPage } = this.state;

    if (title.length >= MIN_CHAR_COUNT) {
      extras.title = title;
    }

    const res = await DealMapper.fetchAll({
      page: currentPage,
      per_page: perPage,
      ...extras,
    });

    const { headers, data } = res;
    const { 'x-page': page, 'x-per-page': perPageCount, 'x-total': total } = headers;
    this.setState({
      deals: data.deals,
      page,
      perPage: perPageCount,
      total,
    });
  }, 400);

  constructor(props) {
    super(props);

    this.state = {
      deals: [],
      page: 1,
      perPage: 25,
      total: null,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  async componentDidMount() {
    this.fetchDeals();
  }

  handleDelete(id) {
    confirmDelete(async () => {
      await DealMapper.delete(id);
      this.fetchDeals();
    });
  }

  handleSearch(title) {
    this.fetchDeals(1, title);
  }

  render() {
    const { deals, page, perPage, total } = this.state;

    return (
      <div>
        <DealsList
          deals={deals}
          onDelete={this.handleDelete}
          onSearch={this.handleSearch}
        />

        <ReactPaginate
          onPageChange={({ selected }) => this.fetchDeals(selected + 1)}
          initialPage={parseInt(page, 10) - 1}
          pageCount={Math.ceil((total / perPage))}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          disableInitialCallback
          containerClassName="pagination"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
        />
      </div>
    );
  }
}

export default DealsListPage;
