import React, { Component } from 'react';
import { withToastManager } from 'react-toast-notifications/dist/ToastProvider';

import RuleForm from './RuleForm';
import RuleMapper from '../../utils/mappers/rule-mapper';

class RuleEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rule: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const res = await RuleMapper.fetchAll();
    this.setState({ rule: res.data.rules[0] });
  }

  async handleSubmit(rule, formikBag) {
    const { toastManager } = this.props;
    try {
      await RuleMapper.patch(rule.id, { rule });
      toastManager.add('Successfully updated rule.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e) {
      formikBag.setErrors(e.response.data.error.message);
    }
    formikBag.setSubmitting(false);
  }

  render() {
    const { rule } = this.state;

    if (!rule) {
      return null;
    }

    return (

      <RuleForm onSubmit={this.handleSubmit} initialValues={rule} />

    );
  }
}

export default withToastManager(RuleEditPage);
