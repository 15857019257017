import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Badge } from 'reactstrap';

import UrlForm from '../UrlForm';

const propTypes = {
  urls: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const UrlsList = ({ urls, onDelete }) => (
  <Table>
    <thead>
      <tr>
        <th>Value</th>
        <th>Retailer</th>
        <th className="text-center">Book Format</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {urls.map(url => (
        <tr key={url.id}>
          <td>
            <code>
              <a
                href={url.value}
                target="_blank"
                rel="noopener noreferrer"
              >
                {url.value}
              </a>
            </code>
          </td>
          <td>{url.retailer.name}</td>
          <td className="text-center"><Badge color="secondary">{url.book_format.name}</Badge></td>
          <td>
            <Button
              onClick={() => onDelete(url.id)}
              color="link"
              className="danger"
            >
              <i className="fas fa-trash-alt" />
            </Button>
          </td>
        </tr>
      ))}

      <UrlForm />
    </tbody>
  </Table>
);

UrlsList.propTypes = propTypes;

export default UrlsList;
