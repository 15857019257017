import { templateSettings, template, zip, tap, forOwn } from 'lodash';

import routes from '../routes';

const ROUTE_REGEX = /:([a-zA-Z]+)/g;
templateSettings.interpolate = ROUTE_REGEX;

const interpolateRoute = (route, urlParams) => {
  const matches = route.match(/:([a-zA-Z]+)/g);

  if (!matches) {
    return route;
  }

  const sanitizedMatches = matches.map(m => m.replace(':', ''));
  const routeConfig = tap({}, (obj) => {
    zip(sanitizedMatches, urlParams).forEach(([paramKey, paramValue]) => {
      obj[paramKey] = paramValue;
    });
  });
  return template(route)(routeConfig);
};

const processRouteMap = routeMap => tap({}, obj => forOwn(routeMap, (v, k) => {
  obj[k] = urlParams => interpolateRoute(v, urlParams);
}));

const processRoutes = processRouteMap(routes);

const getRoute = (key, ...moreArgs) => {
  const routeFactory = processRoutes[key];
  return routeFactory(moreArgs);
};

export default getRoute;
